
import Vue from 'vue';
import axios from 'axios';
import AlertComponent from '@/components/AlertComponent.vue';

interface FileObject {
  name: string;
  url: string;
}

export default Vue.extend({
  name: 'FilesView',
  components: { AlertComponent },
  data: () => ({
    files: [] as FileObject[],
    grids: [] as FileObject[][],
    alertData: [] as object[],
  }),
  created() {
    this.createFileGrids();
    this.getFiles();
  },
  methods: {
    async getFiles() {
      this.$store.commit('setProgressLoader', true);
      await axios
        .get(`${this.$store.getters.getApiUrl}/uploads`, {
          headers: {
            'x-api-key': this.$store.getters.getApiToken,
          },
        })
        .then((response) => {
          if (response.status !== 200) {
            this.alertData.push({
              id: this.alertData.length,
              alertType: 'error',
              message: 'There is something wrong. Please contact an administrator.',
            });
          } else {
            console.log(response.data);
            response.data.forEach((value: {
              // eslint-disable-next-line camelcase
              file_name: string,
            }) => {
              this.files.push({
                name: value.file_name,
                url: '',
              });
            });
          }
        })
        .catch((error) => {
          const errorData = {
            id: '',
            alertType: 'error',
            message: '',
          };
          if (error.code === 'ERR_NETWORK') {
            errorData.message = 'Network error. Please check your connection.';
          } else {
            errorData.message = 'Server side upload configuration error. Please contact an administrator.';
          }

          if (errorData.message !== '') {
            this.alertData.push(errorData);
          }

          /** Authentication checker
           * @TODO optimize this code, make a base interface for this codes aside from validating
           * each page route changed in router/index.ts
           * */
          if (error.response.data.detail === 'Invalid API token.'
            || error.response.status === 401) {
            this.$store.commit('updateAuthenticatedAccount', {
              accountId: null,
              apiToken: null,
            });
            this.$router.push({
              name: 'login',
              params: {
                errorEx: 'Invalid authentication. Please login.',
              },
            });
          }
        })
        .finally(() => {
          this.$store.commit('setProgressLoader', false);
        });
    },
    createFileGrids() {
      const screenWidth = window.innerWidth;
      let filesPerGrid;
      if (screenWidth < 960) {
        // Mobile and Tablet: 1 file per row
        filesPerGrid = 1;
      } else {
        // Desktop: 4 files per row
        filesPerGrid = 4;
      }

      for (let i = 0; i < this.files.length; i += filesPerGrid) {
        this.grids.push(this.files.slice(i, i + filesPerGrid));
      }
    },
    getFileIcon(fileName: string) {
      // Implement logic to determine the appropriate file icon based on the file extension
      // For example, you can use a switch statement or a map of file extensions to icons
      // This is a placeholder function and should be customized to your requirements
      const fileExtension = fileName ? fileName.toLowerCase()
        .split('.')
        .pop() : '';
      switch (fileExtension) {
        case 'pdf':
          return 'mdi-file-pdf';
        case 'jpg':
        case 'jpeg':
        case 'png':
          return 'mdi-file-image';
        case 'mp4':
        case 'avi':
        case 'mov':
          return 'mdi-file-video';
        default:
          return 'mdi-file';
      }
    },
    openFile(file: FileObject) {
      // Implement logic to open the file (e.g., redirect to the file URL)
      // This is a placeholder function and should be customized to your requirements
      // window.open(file.url, '_blank');
    },
  },
});
