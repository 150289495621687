
import Vue from 'vue';
import axios from 'axios';
import store from '../store';
// import AlertComponent from '@/components/AlertComponent.vue';

export default Vue.extend({
  name: 'TreeViewView',
  components: {
    // AlertComponent,
  },
  data: () => ({
    snackbar: {
      enable: false,
      timeout: 2000,
      text: '',
      color: 'white',
      light: true,
      dark: false,
    },
    items: [] as object[],
    openIds: [],
  }),
  props: {
    clearOpenIds: Boolean,
  },
  methods: {
    setSnackbar(message: string, type: string, timeout = 2000) {
      this.snackbar.enable = true;
      this.snackbar.text = message;
      this.snackbar.timeout = timeout;

      if (type === 'error') {
        this.snackbar.color = 'red';
        this.snackbar.dark = true;
        this.snackbar.light = false;
      } else if (type === 'success') {
        this.snackbar.color = 'green';
        this.snackbar.dark = true;
        this.snackbar.light = false;
      } else if (type === 'info') {
        this.snackbar.color = 'white';
        this.snackbar.dark = false;
        this.snackbar.light = true;
      }
    },
    handleOpenOrClose(data: []) {
      if (data.length > 0) {
        this.$store.commit('setStoredOpenedItems', data);
      }
    },
    goToItemDetails(data: {
      id: string,
      name: string,
      additionalInfo: {
        amount: string,
        description: string,
        itemImageThumbnailUrl: string,
        itemImageUrl: string,
        boxNumber: string,
        boxImageThumbnailUrl: string,
        boxImageUrl: string,
        shelfName: string,
        roomName: string,
        floorName: string,
        houseName: string,
        metas: [],
      },
    }) {
      if (data.additionalInfo) {
        this.$router.push({
          name: 'detailed-item-view',
          params: {
            text: data.name,
            value: data.id,
            amount: data.additionalInfo.amount,
            description: data.additionalInfo.description,
            itemImageThumbnailUrl: data.additionalInfo.itemImageThumbnailUrl,
            itemImageUrl: data.additionalInfo.itemImageUrl,
            boxNumber: data.additionalInfo.boxNumber,
            boxImageThumbnailUrl: data.additionalInfo.boxImageThumbnailUrl,
            boxImageUrl: data.additionalInfo.boxImageUrl,
            shelfName: data.additionalInfo.shelfName,
            roomName: data.additionalInfo.roomName,
            floorName: data.additionalInfo.floorName,
            houseName: data.additionalInfo.houseName,
            metas: data.additionalInfo.metas ? JSON.stringify(data.additionalInfo.metas) : '',
          },
        });
      }
    },
    async loadData() {
      this.$store.commit('setProgressLoader', true);
      const treeViewStructure = {
        id: NaN,
        name: '',
        children: [] as object[],
      };

      await axios
        .get(`${this.$store.getters.getApiUrl}/api/homes/get/all`, {
          headers: {
            'X-AUTH-TOKEN': this.$store.getters.getApiToken,
          },
          params: {
            account_id: this.$store.getters.getAccountId,
            'order[id]': 'ASC',
          },
          responseType: 'json',
        })
        .then((response) => {
          response.data.forEach((houseValue: {
            id: number,
            name: string,
            floors: [],
          }) => {
            // house
            const houseItem = { ...treeViewStructure };
            houseItem.id = houseValue.id;
            houseItem.name = `House: ${houseValue.name}`;
            houseItem.children = [];

            // floors
            houseValue.floors.forEach((floorValue: { id: number, name: string, rooms: [] }) => {
              const floorItems = { ...treeViewStructure };
              floorItems.id = floorValue.id;
              floorItems.name = `Floor: ${floorValue.name}`;
              floorItems.children = [];
              houseItem.children.push(floorItems);

              // rooms
              floorValue.rooms.forEach((roomValue: { id: number, name: string, cupboards: [] }) => {
                const roomItems = { ...treeViewStructure };
                roomItems.id = roomValue.id;
                roomItems.name = `Room: ${roomValue.name}`;
                roomItems.children = [];
                floorItems.children.push(roomItems);

                // shelves
                roomValue.cupboards.forEach((shelfValue: {
                  id: number,
                  name: string,
                  boxes: [],
                }) => {
                  const shelfItems = { ...treeViewStructure };
                  shelfItems.id = shelfValue.id;
                  shelfItems.name = `Shelf: ${shelfValue.name}`;
                  shelfItems.children = [];
                  roomItems.children.push(shelfItems);

                  // boxes
                  shelfValue.boxes.forEach((boxValue: {
                    id: number,
                    number: number,
                    items: [],
                    // eslint-disable-next-line camelcase
                    image_id: {
                      // eslint-disable-next-line camelcase
                      file_extension: string,
                      // eslint-disable-next-line camelcase
                      is_landscape: boolean,
                      uuid: string,
                    },
                  }) => {
                    const boxItems = { ...treeViewStructure };
                    boxItems.id = boxValue.id;
                    boxItems.name = `Box: ${boxValue.number.toString()}`;
                    boxItems.children = [];
                    shelfItems.children.push(boxItems);

                    // items
                    boxValue.items.forEach((itemValue: {
                      id: number,
                      name: string,
                      description: string,
                      amount: number,
                      itemMetas: []
                      // eslint-disable-next-line camelcase
                      image_id: {
                        // eslint-disable-next-line camelcase
                        file_extension: string,
                        // eslint-disable-next-line camelcase
                        is_landscape: boolean,
                        uuid: string,
                      },
                    }) => {
                      const itemItems = {
                        ...treeViewStructure,
                        additionalInfo: {
                          amount: NaN,
                          description: '',
                          itemImageThumbnailUrl: '',
                          itemImageUrl: '',
                          boxNumber: NaN,
                          boxImageThumbnailUrl: '',
                          boxImageUrl: '',
                          shelfName: '',
                          roomName: '',
                          floorName: '',
                          houseName: '',
                          metas: [],
                        },
                      };
                      itemItems.id = itemValue.id;
                      itemItems.name = itemValue.name;
                      itemItems.additionalInfo = {
                        amount: itemValue.amount,
                        description: itemValue.description,
                        itemImageThumbnailUrl: itemValue.image_id ? `${this.$store.getters.getApiUrl}/uploads/thumbnails/thumbnail_${itemValue.image_id.uuid}.${itemValue.image_id.file_extension}` : '',
                        itemImageUrl: itemValue.image_id ? `${this.$store.getters.getApiUrl}/uploads/images/${itemValue.image_id.uuid}.${itemValue.image_id.file_extension}` : '',
                        boxNumber: boxValue.number,
                        boxImageThumbnailUrl: boxValue.image_id ? `${this.$store.getters.getApiUrl}/uploads/thumbnails/thumbnail_${boxValue.image_id.uuid}.${boxValue.image_id.file_extension}` : '',
                        boxImageUrl: boxValue.image_id ? `${this.$store.getters.getApiUrl}/uploads/images/${boxValue.image_id.uuid}.${boxValue.image_id.file_extension}` : '',
                        shelfName: shelfValue.name,
                        roomName: roomValue.name,
                        floorName: floorValue.name,
                        houseName: houseValue.name,
                        metas: itemValue.itemMetas,
                      };
                      itemItems.children = [];
                      boxItems.children.push(itemItems);
                    });
                  });
                });
              });
            });

            this.items.push(houseItem);
          });

          // open up the previous opened items
          this.openIds = this.$store.getters.storedOpenedItems;
        })
        .catch((error) => {
          /** Authentication checker
           * @TODO optimize this code, make a base interface for this codes aside from validating
           * each page route changed in router/index.ts
           * */
          if (error.response.data.message === 'Invalid credentials.'
            || error.response.status === 401) {
            this.$store.commit('updateAuthenticatedAccount', {
              accountId: null,
              apiToken: null,
            });
            this.$router.push({
              name: 'login',
              params: {
                errorEx: 'Invalid authentication. Please login.',
              },
            });
          }

          const errorData = {
            id: '',
            alertType: 'error',
            message: '',
          };
          if (error.code === 'ERR_NETWORK') {
            errorData.message = 'Network error. Please check your connection.';
          } else {
            errorData.message = error.response.data.violations.shift().message;
          }

          // this.alertData.push(errorData);
          this.setSnackbar(errorData.message, errorData.alertType);
        })
        .finally(() => {
          this.$store.commit('setProgressLoader', false);
        });
    },
  },
  created() {
    this.loadData();
  },
  mounted() {
    this.$store.commit('setBackButtonEnable', true);
    this.$store.commit('setAppBarExtraData', {
      enable: true,
      pageTitle: 'Treeview',
      fabButton: {
        enable: false,
      },
    });
  },
  destroyed() {
    this.$store.commit('setBackButtonEnable', false);
    this.$store.commit('setAppBarExtraData', {
      enable: false,
      fabButton: {},
    });
  },
  beforeRouteEnter(to, from, next) {
    if (from.name !== 'detailed-item-view') {
      // if the previous page is not from detailed item view, clear the open ids of tree view
      store.commit('setStoredOpenedItems', []);
    }
    // to continue
    next();
  },
});
