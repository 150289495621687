import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import authModule from '@/store/modules/auth';
import progressLoaderModule from '@/store/modules/progressLoader';
import appModule from '@/store/modules/app';
import appBarExtraModule from '@/store/modules/appBarExtra';
import treeViewModule from '@/store/modules/treeView';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersistence({
  key: process.env.VUE_APP_NAME.toLowerCase(),
  storage: window.localStorage,
});

export default new Vuex.Store({
  plugins: [
    vuexLocalStorage.plugin,
  ],
  state: {
    //
  },
  getters: {
    //
  },
  mutations: {
    //
  },
  actions: {
    // fetchApiUrl({ commit }) {
    //   commit('updateApiUrl', this.state.apiUrl);
    // },
    // fetchApiToken({ commit }) {
    //   commit('updateApiToken', this.state.apiToken);
    // },
  },
  modules: {
    progressLoader: progressLoaderModule,
    auth: authModule,
    app: appModule,
    appBarExtra: appBarExtraModule,
    treeView: treeViewModule,
  },
});
