const progressLoaderModule = {
  state: {
    isLoading: false,
  },
  getters: {
    isLoading(state: { isLoading: boolean; }) {
      return state.isLoading;
    },
  },
  mutations: {
    setProgressLoader(state: { isLoading: boolean; }, isLoading: boolean) {
      state.isLoading = isLoading;
    },
  },
};

export default progressLoaderModule;
