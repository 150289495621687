
import Vue from 'vue';

export default Vue.extend({
  name: 'DetailedItemView',
  components: {
    //
  },
  props: {
    text: String,
    value: Number,
    amount: Number,
    description: String,
    itemImageThumbnailUrl: String,
    itemImageUrl: String,
    boxNumber: Number,
    boxImageThumbnailUrl: String,
    boxImageUrl: String,
    shelfName: String,
    roomName: String,
    floorName: String,
    houseName: String,
    metas: String,
  },
  data: () => ({
    itemMetas: [] as object[],
    itemLocation: ['House', 'Floor', 'Room', 'Shelf', 'Box'],
  }),
  created() {
    //
  },
  mounted() {
    if (!this.value) {
      this.$router.back();
    }

    this.$store.commit('setBackButtonEnable', true);
    this.$store.commit('setAppBarExtraData', {
      enable: true,
      pageTitle: 'Item Details',
      fabButton: {
        enable: false,
        icon: 'mdi-content-save',
      },
    });

    this.itemMetas = this.metas ? JSON.parse(this.metas) : '';
  },
  destroyed() {
    this.$store.commit('setBackButtonEnable', false);
    this.$store.commit('setAppBarExtraData', {
      enable: false,
      fabButton: {},
    });
  },
  computed: {
    //
  },
  watch: {
    //
  },
  methods: {
    //
  },
});
