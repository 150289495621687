
import Vue from 'vue';
import AlertComponent from '@/components/AlertComponent.vue';
// import RoomTabComponent from '@/components/tabs/RoomTabComponent.vue';
// import FloorTabComponent from '@/components/tabs/FloorTabComponent.vue';
// import BoxTabComponent from '@/components/tabs/BoxTabComponent.vue';
// import HouseTabComponent from '@/components/tabs/HouseTabComponent.vue';
// import ShelfTabComponent from '@/components/tabs/ShelfTabComponent.vue';
import { mapGetters } from 'vuex';
import axios from 'axios';

export default Vue.extend({
  name: 'UploadView',
  components: {
    AlertComponent,
    // HouseTabComponent,
    // FloorTabComponent,
    // RoomTabComponent,
    // ShelfTabComponent,
    // BoxTabComponent,
  },
  data: () => ({
    valid: false,
    // tab: null,
    // tabItems: ['House', 'Floor', 'Room', 'Shelf', 'Box'],
    browseImage: null,
    browseImageRules: [
      (v: File) => !!v || 'File(s) are required',
      // (v: File) => !v || v.size < (1000 * 1000000) || 'File size should be less than 1 gb',
    ],
    browseImageFiles: '',
    alertData: [] as object[],
  }),
  computed: {
    // I cannot combine mapGetters with formEl(), so the solution was to create another
    // property called isFirstLogin() to be called out from the alert component
    // ...mapGetters(['isFirstLogin']),
    isFirstLogin() {
      return this.$store.getters.isFirstLogin;
    },
    formEl() {
      return (this.$refs.form as Vue & {
        validate: () => boolean,
        reset: () => boolean
      });
    },
  },
  methods: {
    testSubmitData() {
      this.formEl.validate();
      // (this.$refs.form as Vue & { validate: () => boolean, reset: () => boolean }).validate();
    },
    setIsFirstLoginFalseAfterLogin() {
      this.$store.commit('setFirstLogin', false);
    },
    // updateTabForm(tabIndex: number) {
    //   this.$root.$emit(`${this.tabItems[tabIndex].toLowerCase()}TabUpdateCollection`, true);
    // },
    browseImageFileOnChangeEvent(files: never) {
      // eslint-disable-next-line prefer-destructuring
      this.browseImageFiles = files;
    },
    async submitButton() {
      this.testSubmitData();
      this.alertData = [] as object[];
      if (this.valid) {
        this.$store.commit('setProgressLoader', true);
        const data = new FormData();
        // data.append('files', this.browseImageFiles);
        for (let i = 0; i < this.browseImageFiles.length; i += 1) {
          data.append('files', this.browseImageFiles[i]);
        }

        await axios
          .post(`${this.$store.getters.getApiUrl}/uploads/upload`, data, {
            headers: {
              'x-api-key': this.$store.getters.getApiToken,
            },
          })
          .then((response) => {
            if (response.status !== 201) {
              this.$store.commit('setProgressLoader', false);
              this.alertData.push({
                id: this.alertData.length,
                alertType: 'error',
                message: 'There is something wrong. Please contact an administrator.',
              });
            } else {
              const resMessage = response.data.detail;

              this.alertData.push({
                id: this.alertData.length,
                alertType: 'success',
                message: `${resMessage}`,
              });
              this.formEl.reset();

              setTimeout(() => {
                this.alertData.pop();
              }, 8000);
            }
          })
          .catch((error) => {
            this.$store.commit('setProgressLoader', false);
            const errorData = {
              id: '',
              alertType: 'error',
              message: '',
            };
            if (error.code === 'ERR_NETWORK') {
              errorData.message = 'Network error. Please check your connection.';
            } else {
              errorData.message = 'Server side upload configuration error. Please contact an administrator.';
            }

            if (errorData.message !== '') {
              this.alertData.push(errorData);
            }

            /** Authentication checker
             * @TODO optimize this code, make a base interface for this codes aside from validating
             * each page route changed in router/index.ts
             * */
            if (error.response.data.detail === 'Invalid API token.'
              || error.response.status === 401) {
              this.$store.commit('updateAuthenticatedAccount', {
                accountId: null,
                apiToken: null,
              });
              this.$router.push({
                name: 'login',
                params: {
                  errorEx: 'Invalid authentication. Please login.',
                },
              });
            }
          })
          .finally(() => {
            this.$store.commit('setProgressLoader', false);
          });
      }
    },
  },
  mounted() {
    setTimeout(() => this.setIsFirstLoginFalseAfterLogin(), 5000);
  },
});
