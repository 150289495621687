
import Vue from 'vue';

export default Vue.extend({
  name: 'AlertComponent',
  props: [
    'message',
    'alertType',
  ],
  data: () => ({
    alert: true,
  }),
});
