const appModule = {
  state: {
    isBackButtonEnable: false,
  },
  getters: {
    isBackButtonEnable(state: { isBackButtonEnable: boolean; }) {
      return state.isBackButtonEnable;
    },
  },
  mutations: {
    setBackButtonEnable(state: { isBackButtonEnable: boolean; }, isBackButtonEnable: boolean) {
      state.isBackButtonEnable = isBackButtonEnable;
    },
  },
};

export default appModule;
