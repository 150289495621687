
import Vue from 'vue';
import axios from 'axios';
import AlertComponent from '@/components/AlertComponent.vue';
import goTo from 'vuetify/lib/services/goto';

export default Vue.extend({
  name: 'AccountSettingView',
  components: {
    AlertComponent,
  },
  data: () => ({
    valid: false,
    name: '',
    nameRules: [
      (v: string) => !!v || 'Name is required',
    ],
    nameElLoading: true,
    username: '',
    usernameRules: [
      (v: string) => !!v || 'Username is required',
      (v: string) => /.+@.+/.test(v) || 'Username must be a valid E-mail',
    ],
    usernameELLoading: true,
    alertData: [] as object[],
    currentName: '',
    currentUsername: '',
    submitBtnProps: {
      disable: true,
      isLight: true,
      isDark: false,
    },
    // afterEmailChange: false,
  }),
  computed: {
    formEl() {
      return (this.$refs.form as Vue & {
        validate: () => boolean,
        reset: () => boolean
      });
    },
  },
  watch: {
    name() {
      if (this.name !== this.currentName) {
        this.submitBtnProps = {
          disable: false,
          isLight: false,
          isDark: true,
        };
      } else {
        this.submitBtnProps = {
          disable: true,
          isLight: true,
          isDark: false,
        };
      }
    },
    currentName() {
      if (this.name !== this.currentName) {
        this.submitBtnProps = {
          disable: false,
          isLight: false,
          isDark: true,
        };
      } else {
        this.submitBtnProps = {
          disable: true,
          isLight: true,
          isDark: false,
        };
      }
    },
    username() {
      if (this.username !== this.currentUsername) {
        this.submitBtnProps = {
          disable: false,
          isLight: false,
          isDark: true,
        };
      } else {
        this.submitBtnProps = {
          disable: true,
          isLight: true,
          isDark: false,
        };
      }
    },
    currentUsername() {
      if (this.username !== this.currentUsername) {
        this.submitBtnProps = {
          disable: false,
          isLight: false,
          isDark: true,
        };
      } else {
        this.submitBtnProps = {
          disable: true,
          isLight: true,
          isDark: false,
        };
      }
    },
  },
  methods: {
    testSubmitData() {
      this.formEl.validate();
    },
    testChanges() {
      return (this.name !== this.currentName) || (this.username !== this.currentUsername);
    },
    async submitButton() {
      this.testSubmitData();
      this.alertData = [] as object[];

      if (this.valid && this.testChanges()) {
        this.$store.commit('setProgressLoader', true);
        await axios
          .put(`${this.$store.getters.getApiUrl}/api/accounts/${this.$store.getters.getAccountId}`, {
            name: this.name,
            email: this.username,
          }, { headers: { 'X-AUTH-TOKEN': this.$store.getters.getApiToken } })
          .then((response) => {
            if (response.status !== 200) {
              this.alertData.push({
                id: this.alertData.length,
                alertType: 'error',
                message: 'There is something wrong while saving. Please contact an administrator.',
              });
            } else {
              this.alertData.push({
                id: this.alertData.length,
                alertType: 'success',
                message: 'Successfully updated account information',
              });

              this.currentName = this.name;
              this.currentUsername = this.username;
            }
          })
          .catch((error) => {
            /** Authentication checker
             * @TODO optimize this code, make a base interface for this codes aside from validating
             * each page route changed in router/index.ts
             * */
            if (error.response.data.message === 'Invalid credentials.'
              || error.response.status === 401) {
              this.$store.commit('updateAuthenticatedAccount', {
                accountId: null,
                apiToken: null,
              });
              this.$router.push({
                name: 'login',
                params: {
                  errorEx: 'Invalid authentication. Please login.',
                },
              });
            }

            const errorData = {
              id: '',
              alertType: 'error',
              message: '',
            };
            if (error.code === 'ERR_NETWORK') {
              errorData.message = 'Network error. Please check your connection.';
            } else {
              // if email got an error, reset the form and call loadAccount
              // if (error.response.data.violations[0].propertyPath === 'email') {
              //   this.loadAccount();
              // }

              errorData.message = error.response.data.violations.shift().message;
            }

            this.alertData.push(errorData);

            // scroll up top to show error message
            goTo(0);
          })
          .finally(() => {
            this.$store.commit('setProgressLoader', false);
          });
      }
    },
    async loadAccount() {
      await axios
        .get(`${this.$store.getters.getApiUrl}/api/accounts/${this.$store.getters.getAccountId}`, {
          headers: {
            'X-AUTH-TOKEN': this.$store.getters.getApiToken,
          },
          responseType: 'json',
        })
        .then((response) => {
          this.name = response.data.name;
          this.username = response.data.email;

          this.currentName = this.name;
          this.nameElLoading = false;
          this.currentUsername = this.username;
          this.usernameELLoading = false;
        })
        .catch((error) => {
          /** Authentication checker
           * @TODO optimize this code, make a base interface for this codes aside from validating
           * each page route changed in router/index.ts
           * */
          if (error.response.data.message === 'Invalid credentials.'
            || error.response.status === 401) {
            this.$store.commit('updateAuthenticatedAccount', {
              accountId: null,
              apiToken: null,
            });
            this.$router.push({
              name: 'login',
              params: {
                errorEx: 'Invalid authentication. Please login.',
              },
            });
          }

          const errorData = {
            id: '',
            alertType: 'error',
            message: '',
          };
          if (error.code === 'ERR_NETWORK') {
            errorData.message = 'Network error. Please check your connection.';
          } else {
            errorData.message = 'Error pulling data from server. Try to reload or close the app then come back.';
          }

          this.alertData.push(errorData);
        });
    },
    // goToSettingsPage() {
    //   this.$router.push({ name: 'settings' });
    // },
    goToChangePasswordPage() {
      this.$router.push({ name: 'settings-account-change-password' });
    },
    // logout() {
    //   this.$store.commit('updateAuthenticatedAccount', {
    //     accountId: null,
    //     apiToken: null,
    //   });
    //   this.$router.push({ name: 'login' });
    // },
  },
  created() {
    this.loadAccount();
  },
  mounted() {
    this.$store.commit('setBackButtonEnable', true);
  },
  destroyed() {
    this.$store.commit('setBackButtonEnable', false);
  },
});
