import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import LoginView from '@/views/LoginView.vue';
import DashboardView from '@/views/DashboardView.vue';
import UploadView from '@/views/UploadView.vue';
// import axios from 'axios';
import SearchView from '@/views/SearchView.vue';
import InviteHousematesView from '@/views/InviteHousematesView.vue';
import SettingsView from '@/views/SettingsView.vue';
import AccountSettingView from '@/views/settings/AccountSettingView.vue';
import AccountSettingChangePasswordView
  from '@/views/settings/AccountSettingChangePasswordView.vue';
import MetasSettingView from '@/views/settings/MetasSettingView.vue';
import HousematesSettingView from '@/views/settings/housemates/HousematesSettingView.vue';
import TreeViewView from '@/views/TreeViewView.vue';
import AddItemView from '@/views/AddItemView.vue';
import DetailedItemView from '@/views/DetailedItemView.vue';
import RegisterHousemateView from '@/views/housemates/RegisterHousemateView.vue';
import HousematesSettingListHousematesByHomeIdView
  from '@/views/settings/housemates/HousematesSettingListHousematesByHomeIdView.vue';
import FilesView from '@/views/FilesView.vue';
import store from '../store';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'login',
    props: true,
    component: LoginView,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.SearchView
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: { requiresLogin: true },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    alias: 'home',
    // component: DashboardView,
    component: UploadView,
    meta: { requiresLogin: true },
  },
  {
    path: '/files',
    name: 'files',
    component: FilesView,
    meta: { requiresLogin: true },
  },
  {
    path: '/search',
    name: 'search',
    component: SearchView,
    meta: { requiresLogin: true },
  },
  {
    path: '/add-item',
    name: 'add-item',
    component: AddItemView,
    meta: { requiresLogin: true },
  },
  {
    path: '/invite-housemates',
    name: 'invite-housemates',
    component: InviteHousematesView,
    meta: { requiresLogin: true },
  },
  {
    path: '/items/view',
    name: 'detailed-item-view',
    component: DetailedItemView,
    props: true,
    meta: { requiresLogin: true },
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView,
    meta: { requiresLogin: true },
  },
  {
    path: '/settings/account',
    name: 'settings-account',
    component: AccountSettingView,
    meta: { requiresLogin: true },
  },
  {
    path: '/settings/account/change-password',
    name: 'settings-account-change-password',
    component: AccountSettingChangePasswordView,
    meta: { requiresLogin: true },
  },
  {
    path: '/settings/housemates',
    name: 'settings-housemates',
    component: HousematesSettingView,
    meta: { requiresLogin: true },
  },
  {
    path: '/settings/housemates/:homeId',
    name: 'settings-housemates-list-housemates-by-home-id',
    component: HousematesSettingListHousematesByHomeIdView,
    meta: { requiresLogin: true },
  },
  {
    path: '/settings/metas',
    name: 'settings-metas',
    component: MetasSettingView,
    meta: { requiresLogin: true },
  },
  {
    path: '/settings/tree-view',
    name: 'settings-tree-view',
    component: TreeViewView,
    meta: { requiresLogin: true },
  },
  {
    path: '/housemates/join/:backendUrl/:secretToken',
    name: 'housemates-join',
    component: RegisterHousemateView,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// Navigation Guard
router.beforeEach((to, from, next) => {
  // Reset app bar states
  store.commit('setBackButtonEnable', false);
  store.commit('setAppBarExtraData', {
    enable: false,
    fabButton: {},
  });
  store.commit('setAppBarExtraAction', '');

  if (to.name === 'login' && store.getters.isAuthenticated) {
    next('/dashboard');
  }

  // Check route with requires login
  if (to.matched.some((record) => record.meta.requiresLogin)) {
    if (store.getters.isAuthenticated) {
      /** Removed to optimize routes changing
       // Validate the apiToken
       // axios.get(`${store.getters.getApiUrl}/api/accounts/validate/${store.getters.getApiToken}`)
       //   .then(() => next())
       //   .catch(() => {
       //     // Invalidate authenticated account
       //     store.commit('updateAuthenticatedAccount', {
       //       accountId: null,
       //       apiToken: null,
       //     });
       //     next('/');
       //   });
       */
      next();
    } else {
      next('/');
    }
  } else {
    next();
  }
});

export default router;
