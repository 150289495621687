const treeViewModule = {
  state: {
    storedOpenedItems: [],
  },
  getters: {
    storedOpenedItems(state: { storedOpenedItems: []; }) {
      return state.storedOpenedItems;
    },
  },
  mutations: {
    setStoredOpenedItems(state: { storedOpenedItems: []; }, storedOpenedItems: []) {
      state.storedOpenedItems = storedOpenedItems;
    },
  },
};

export default treeViewModule;
