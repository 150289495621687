
import Vue from 'vue';
import AlertComponent from '@/components/AlertComponent.vue';
import axios from 'axios';

export default Vue.extend({
  name: 'InviteHousematesView',
  components: {
    AlertComponent,
  },
  data: () => ({
    valid: false,
    email: '',
    emailRules: [
      (v: string) => !!v || 'E-Mail is required',
      (v: string) => /.+@.+/.test(v) || 'Invalid E-Mail',
    ],
    houseId: null,
    houseIdRules: [
      (v: number) => !!v || 'House is required',
    ],
    houses: [] as object[],
    alertData: [] as object[],
  }),
  computed: {
    formEl() {
      return (this.$refs.form as Vue & {
        validate: () => boolean,
        reset: () => boolean
      });
    },
  },
  watch: {
    houseId() {
      if (this.houseId) {
        this.alertData = [] as object[];
      }
    },
  },
  created() {
    this.loadHouses();
  },
  methods: {
    testSubmitData() {
      this.formEl.validate();
    },
    async loadHouses() {
      await axios
        .get(`${this.$store.getters.getApiUrl}/api/homes`, {
          headers: {
            'X-AUTH-TOKEN': this.$store.getters.getApiToken,
            // Accept: 'application/ld+json',
          },
          params: {
            account_id: this.$store.getters.getAccountId,
            'order[id]': 'DESC',
          },
          responseType: 'json',
        })
        .then((response) => {
          // console.log(response.data['hydra:member']);
          response.data.forEach((value: { id: number, name: string }) => {
            this.houses.push({
              text: value.name,
              value: value.id,
            });
          });

          // auto select a house if there is only one selection
          if (this.houses.length === 1) {
            const singleHouseData = JSON.parse(JSON.stringify(this.houses[0]));
            this.houseId = singleHouseData.value;
          }
        })
        .catch((error) => {
          /** Authentication checker
           * @TODO optimize this code, make a base interface for this codes aside from validating
           * each page route changed in router/index.ts
           * */
          if (error.response.data.message === 'Invalid credentials.'
            || error.response.status === 401) {
            this.$store.commit('updateAuthenticatedAccount', {
              accountId: null,
              apiToken: null,
            });
            this.$router.push({
              name: 'login',
              params: {
                errorEx: 'Invalid authentication. Please login.',
              },
            });
          }

          const errorData = {
            id: '',
            alertType: 'error',
            message: '',
          };
          if (error.code === 'ERR_NETWORK') {
            errorData.message = 'Network error. Please check your connection.';
          } else {
            errorData.message = error.response.data.violations.shift().message;
          }

          this.alertData.push(errorData);
        });
    },
    async submitButton() {
      this.testSubmitData();
      this.alertData = [] as object[];

      if (this.valid) {
        this.$store.commit('setProgressLoader', true);
        await axios
          .post(`${this.$store.getters.getApiUrl}/api/housemates/invite`, {
            homeId: this.houseId,
            email: this.email,
            originUrl: window.location.origin ? window.location.origin : `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`,
          }, { headers: { 'X-AUTH-TOKEN': this.$store.getters.getApiToken } })
          .then((response) => {
            if (response.status !== 200) {
              this.alertData.push({
                id: this.alertData.length,
                alertType: 'error',
                message: 'There is something wrong while sending invite. Please contact an administrator.',
              });
            } else {
              this.alertData.push({
                id: this.alertData.length,
                alertType: 'success',
                message: 'Successfully sent an invite email. Please ask your invited housemate to check their email inbox or junk folder.',
              });
              this.formEl.reset();
            }
          })
          .catch((error) => {
            /** Authentication checker
             * @TODO optimize this code, make a base interface for this codes aside from validating
             * each page route changed in router/index.ts
             * */
            if (error.response.data.message === 'Invalid credentials.'
              || error.response.status === 401) {
              this.$store.commit('updateAuthenticatedAccount', {
                accountId: null,
                apiToken: null,
              });
              this.$router.push({
                name: 'login',
                params: {
                  errorEx: 'Invalid authentication. Please login.',
                },
              });
            }

            const errorData = {
              id: '',
              alertType: 'error',
              message: '',
            };
            if (error.code === 'ERR_NETWORK') {
              errorData.message = 'Network error. Please check your connection.';
            } else {
              errorData.message = error.response.data.violations.shift().message;
            }

            this.alertData.push(errorData);

            // scroll up top to show error message
            // goTo(0);
          })
          .finally(() => {
            this.$store.commit('setProgressLoader', false);
          });
      }
    },
  },
  mounted() {
    this.$store.commit('setAppBarExtraData', {
      enable: true,
      pageTitle: 'Invite Housemate',
      fabButton: {
        enable: false,
      },
    });
  },
  destroyed() {
    this.$store.commit('setAppBarExtraData', {
      enable: false,
      fabButton: {},
    });
  },
});
