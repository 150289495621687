import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/sw.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
        // eslint-disable-next-line no-param-reassign
        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          if (installingWorker) {
            installingWorker.onstatechange = () => {
              if (installingWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  console.log('New content is available; please refresh.');
                  // Notify the client that a new version is available
                  // eslint-disable-next-line no-use-before-define
                  sendMessageToClient('updateAvailable');
                } else {
                  console.log('Content is cached for offline use.');
                }
              }
            };
          }
        };
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  });
}

function sendMessageToClient(message: string) {
  (navigator.serviceWorker.controller as ServiceWorker | null)?.postMessage(message);
}

// Listen for the message from the Service Worker
navigator.serviceWorker.addEventListener('message', (event) => {
  if (event.data === 'updateAvailable') {
    // Prompt the user to accept the update and reload the page
    // eslint-disable-next-line no-restricted-globals
    if (confirm('A new version is available. Do you want to update?')) {
      sendMessageToClient('skipWaiting');
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
