const authModule = {
  state: {
    isFirstLogin: false,
    isAuthenticated: false,
    apiUrl: '',
    apiToken: null,
    accountId: null,
  },
  getters: {
    isFirstLogin(state: { isFirstLogin: boolean }) {
      return state.isFirstLogin;
    },
    getApiUrl(state: { apiUrl: string; }) {
      return state.apiUrl;
    },
    getApiToken(state: { apiToken: string; }) {
      return state.apiToken;
    },
    isAuthenticated(state: { isAuthenticated: boolean; }) {
      return state.isAuthenticated;
    },
    getAccountId(state: { accountId: number; }) {
      return state.accountId;
    },
  },
  mutations: {
    setFirstLogin(state: { isFirstLogin: boolean; }, isTrue = true) {
      state.isFirstLogin = isTrue;
    },
    updateApiUrl(state: { apiUrl: string; }, apiUrl: string) {
      state.apiUrl = apiUrl;
    },
    // updateApiToken(state: { apiToken: never; isAuthenticated: boolean; }, apiToken: never) {
    //   state.apiToken = apiToken;
    //   state.isAuthenticated = apiToken !== null;
    // },
    // updateAccountId(state: { accountId: never; }, accountId: never) {
    //   state.accountId = accountId;
    // },
    updateAuthenticatedAccount(state: {
      apiToken: null;
      accountId: null;
      isAuthenticated: boolean;
    }, data: { accountId: null, apiToken: null }) {
      state.apiToken = data.apiToken;
      state.accountId = data.accountId;
      state.isAuthenticated = data.apiToken !== null;
    },
  },
};

export default authModule;
