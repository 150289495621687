
import Vue from 'vue';
import axios from 'axios';
import AlertComponent from '@/components/AlertComponent.vue';
import goTo from 'vuetify/lib/services/goto';

export default Vue.extend({
  name: 'AccountSettingChangePasswordView',
  components: {
    AlertComponent,
  },
  data: () => ({
    valid: false,
    hideOldPassword: true,
    oldPassword: '',
    oldPasswordRules: [
      (v: string) => !!v || 'Old Password is required',
    ],
    hideNewPassword: true,
    newPassword: '',
    newPasswordRules: [
      (v: string) => !!v || 'New Password is required',
    ],
    hideConfirmPassword: true,
    confirmPassword: '',
    alertData: [] as object[],
    afterChangePassword: false,
  }),
  computed: {
    formEl() {
      return (this.$refs.form as Vue & {
        validate: () => boolean,
        reset: () => boolean
      });
    },
    confirmPasswordRules() {
      const rules = [];
      if (this.confirmPassword) {
        const rule = (v: string) => (!!v && v) === this.newPassword || 'New and confirm password do not match';
        rules.push(rule);
      } else {
        const rule = (v: string) => !!v || 'Confirm Password is required';
        rules.push(rule);
      }
      return rules;
    },
  },
  watch: {
    confirmPassword: 'testSubmitData',
  },
  methods: {
    testSubmitData() {
      this.formEl.validate();
    },
    async submitButton() {
      this.testSubmitData();
      this.alertData = [] as object[];

      if (this.valid) {
        this.$store.commit('setProgressLoader', true);
        await axios
          .put(`${this.$store.getters.getApiUrl}/api/accounts/change-password/${this.$store.getters.getAccountId}`, {
            oldPassword: this.oldPassword,
            confirmedNewPassword: this.confirmPassword,
          }, { headers: { 'X-AUTH-TOKEN': this.$store.getters.getApiToken } })
          .then((response) => {
            if (response.status !== 200) {
              this.alertData.push({
                id: this.alertData.length,
                alertType: 'error',
                message: 'There is something wrong while saving. Please contact an administrator.',
              });
            } else {
              // this.alertData.push({
              //   id: this.alertData.length,
              //   alertType: 'success',
              //   message: response.data.message,
              // });

              this.afterChangePassword = true;
            }
          })
          .catch((error) => {
            /** Authentication checker
             * @TODO optimize this code, make a base interface for this codes aside from validating
             * each page route changed in router/index.ts
             * */
            if (error.response.data.message === 'Invalid credentials.'
              || error.response.status === 401) {
              this.$store.commit('updateAuthenticatedAccount', {
                accountId: null,
                apiToken: null,
              });
              this.$router.push({
                name: 'login',
                params: {
                  errorEx: 'Invalid authentication. Please login.',
                },
              });
            }

            const errorData = {
              id: '',
              alertType: 'error',
              message: '',
            };
            if (error.code === 'ERR_NETWORK') {
              errorData.message = 'Network error. Please check your connection.';
            } else {
              errorData.message = error.response.data.message;
            }

            this.alertData.push(errorData);

            // scroll up top to show error message
            goTo(0);
          })
          .finally(() => {
            this.$store.commit('setProgressLoader', false);
            this.formEl.reset();
          });
      }
    },
    // goBack() {
    //   this.$router.back();
    // },
    logout() {
      this.$store.commit('updateAuthenticatedAccount', {
        accountId: null,
        apiToken: null,
      });
      this.$router.push({ name: 'login' });
    },
  },
  mounted() {
    this.$store.commit('setBackButtonEnable', true);
  },
  destroyed() {
    this.$store.commit('setBackButtonEnable', false);
  },
});
