const appBarExtraModule = {
  state: {
    appBarExtraData: {
      enable: false,
      pageTitle: '',
      fabButton: {
        enable: true,
        color: 'black',
        top: false,
        left: false,
        bottom: true,
        right: true,
        icon: 'mdi-plus',
      },
    },
    appBarExtraAction: '',
  },
  getters: {
    appBarExtraData(state: {
      appBarExtraData: {
        enable: boolean,
        pageTitle: string,
        fabButton: {
          enable: boolean,
          color: string,
          top: boolean,
          left: boolean,
          bottom: boolean,
          right: boolean,
          icon: string,
        },
      }
    }) {
      return state.appBarExtraData;
    },
    appBarExtraAction(state: { appBarExtraAction: string }) {
      return state.appBarExtraAction;
    },
  },
  mutations: {
    setAppBarExtraData(state: {
      appBarExtraData: {
        enable: boolean,
        pageTitle: string,
        fabButton: {
          enable: boolean,
          color: string,
          top: boolean,
          left: boolean,
          bottom: boolean,
          right: boolean,
          icon: string,
        },
      }
    }, data: {
      enable: false,
      pageTitle: '',
      fabButton: {
        enable: true,
        color: '',
        top: false,
        left: false,
        bottom: true,
        right: true,
        icon: '',
      },
    }) {
      state.appBarExtraData = {
        enable: data.enable,
        pageTitle: data.pageTitle,
        fabButton: {
          enable: data.fabButton.enable ?? state.appBarExtraData.fabButton.enable,
          color: data.fabButton.color ?? state.appBarExtraData.fabButton.color,
          top: data.fabButton.top ?? state.appBarExtraData.fabButton.top,
          left: data.fabButton.left ?? state.appBarExtraData.fabButton.left,
          bottom: data.fabButton.bottom ?? state.appBarExtraData.fabButton.bottom,
          right: data.fabButton.right ?? state.appBarExtraData.fabButton.right,
          icon: data.fabButton.icon ?? state.appBarExtraData.fabButton.icon,
        },
      };
    },
    setAppBarExtraAction(state: { appBarExtraAction: string }, data: string) {
      state.appBarExtraAction = data;
    },
  },
};

export default appBarExtraModule;
