
import Vue from 'vue';
import AlertComponent from '@/components/AlertComponent.vue';
import axios from 'axios';
import goTo from 'vuetify/lib/services/goto';

export default Vue.extend({
  name: 'RegisterHousemateView',
  components: {
    AlertComponent,
  },
  data: () => ({
    valid: false,
    activateFormType: 'registration',
    // url: '',
    // urlRules: [
    //   (v: string) => !!v || 'Url is required',
    // ],
    name: '',
    nameRules: [
      (v: string) => !!v || 'Name is required',
    ],
    emailRules: [
      (v: string) => !!v || 'E-Mail is required',
      (v: string) => /.+@.+/.test(v) || 'Invalid E-Mail',
    ],
    hidePassword: true,
    password: '',
    passwordRules: [
      (v: string) => !!v || 'Password is required',
      // (v: string) => (v || '').length < 8 || 'Password should be at least 8 characters',
    ],
    alertData: [] as object[],
    inviteData: {
      isExpired: false,
      isAlreadyRegistered: false,
      isAlreadyJoined: false,
      home: {
        id: null,
        name: '',
        homeOwnerAccount: {
          id: null,
          name: '',
          email: '',
        },
      },
      housemateEmail: '',
    },
    redirectionCounter: 0,
    maxSecondsToRedirect: 5,
  }),
  computed: {
    formEl() {
      return (this.$refs.form as Vue & {
        validate: () => boolean,
        reset: () => boolean,
        disabled: boolean,
      });
    },
  },
  created() {
    this.loadInviteData(this.$route.params.backendUrl, this.$route.params.secretToken);
  },
  methods: {
    testSubmitData() {
      this.formEl.validate();
    },
    async loadInviteData(backendUrl: string, secretToken: string) {
      if (backendUrl && secretToken) {
        const apiUrl = atob(this.$route.params.backendUrl);
        // this.url = apiUrl;
        this.$store.commit('updateApiUrl', apiUrl);
        this.$store.commit('setProgressLoader', true);

        await axios
          .get(`${apiUrl}/api/housemates/read/${secretToken}`, {
            responseType: 'json',
          })
          .then((response) => {
            if (response.data) {
              this.inviteData = response.data.data;

              if (!this.inviteData.isAlreadyRegistered && !this.inviteData.isAlreadyJoined) {
                this.activateFormType = 'registration';
              } else if (this.inviteData.isAlreadyRegistered && !this.inviteData.isAlreadyJoined) {
                this.activateFormType = 'join';
              } else {
                this.activateFormType = 'redirection';
                this.alertData.push({
                  id: this.alertData.length,
                  alertType: 'info',
                  message: `You already joined as housemate in the house (${this.inviteData.home.name}) of ${this.inviteData.home.homeOwnerAccount.name}`,
                });

                this.redirectionCounter = this.maxSecondsToRedirect;
                const timer = setInterval(() => {
                  if (this.redirectionCounter === 0) {
                    clearInterval(timer);
                    this.$router.replace({ name: 'dashboard' });
                  } else {
                    this.redirectionCounter -= 1;
                  }
                }, 1000);
              }
            }
          })
          .catch((error) => {
            const errorData = {
              id: '',
              alertType: 'error',
              message: '',
            };

            if (error.code === 'ERR_NETWORK') {
              errorData.message = 'Network error. Please check your connection.';
            } else if (error.response.data.data.isExpired) {
              this.activateFormType = 'redirection';
              errorData.message = error.response.data.message;
              this.redirectionCounter = this.maxSecondsToRedirect;
              const timer = setInterval(() => {
                if (this.redirectionCounter === 0) {
                  clearInterval(timer);
                  this.$router.replace({ name: 'dashboard' });
                } else {
                  this.redirectionCounter -= 1;
                }
              }, 1000);
            } else {
              errorData.message = error.response.data.violations.shift().message;
            }

            this.alertData.push(errorData);
          })
          .finally(() => {
            this.$store.commit('setProgressLoader', false);
          });
      }
    },
    async joinSubmitButton(accountId: number) {
      const getAccountId = accountId ?? this.$store.getters.getAccountId;
      this.alertData = [] as object[];

      this.$store.commit('setProgressLoader', true);

      await axios
        .post(`${this.$store.getters.getApiUrl}/api/housemates/join`, {
          homeId: this.inviteData.home.id,
          accountId: getAccountId,
        }, { headers: { 'X-AUTH-TOKEN': this.$store.getters.getApiToken } })
        .then((response) => {
          if (response.status !== 200) {
            this.alertData.push({
              id: this.alertData.length,
              alertType: 'error',
              message: 'There is something wrong while joining. Please contact an administrator.',
            });
          } else {
            this.activateFormType = 'redirection';

            this.alertData.push({
              id: this.alertData.length,
              alertType: 'success',
              message: response.data.message,
            });

            this.redirectionCounter = this.maxSecondsToRedirect;
            const timer = setInterval(() => {
              if (this.redirectionCounter === 0) {
                clearInterval(timer);
                this.$router.replace({ name: 'dashboard' });
              } else {
                this.redirectionCounter -= 1;
              }
            }, 1000);
          }
        })
        .catch((error) => {
          /** Authentication checker
           * @TODO optimize this code, make a base interface for this codes aside from validating
           * each page route changed in router/index.ts
           * */
          if (error.response.data.message === 'Invalid credentials.'
            || error.response.status === 401) {
            this.$store.commit('updateAuthenticatedAccount', {
              accountId: null,
              apiToken: null,
            });
            this.$router.push({
              name: 'login',
              params: {
                errorEx: 'Invalid authentication. Please login.',
              },
            });
          }

          const errorData = {
            id: '',
            alertType: 'error',
            message: '',
          };
          if (error.code === 'ERR_NETWORK') {
            errorData.message = 'Network error. Please check your connection.';
          } else {
            errorData.message = error.response.data.violations.shift().message;
          }

          this.alertData.push(errorData);

          // scroll up top to show error message
          // goTo(0);
        })
        .finally(() => {
          this.$store.commit('setProgressLoader', false);
        });
    },
    async submitButton() {
      this.testSubmitData();
      this.alertData = [] as object[];

      if (this.valid) {
        this.$store.commit('setProgressLoader', true);
        await axios
          .post(`${this.$store.getters.getApiUrl}/api/accounts`, {
            email: this.inviteData.housemateEmail,
            name: this.name,
            password: this.password,
          }, { headers: { 'X-AUTH-TOKEN': this.$store.getters.getApiToken } })
          .then(async (response) => {
            if (response.status !== 201) {
              this.alertData.push({
                id: this.alertData.length,
                alertType: 'error',
                message: 'There is something wrong while submitting your account registration. Please contact an administrator.',
              });
            } else {
              this.alertData.push({
                id: this.alertData.length,
                alertType: 'success',
                message: 'Successfully register an account!',
              });
              // this.formEl.reset();
              this.activateFormType = '';

              // Set the api token
              this.$store.commit('updateAuthenticatedAccount', {
                accountId: response.data.account_id,
                apiToken: response.data.token,
              });

              // Join as housemate
              setTimeout(async () => {
                await this.joinSubmitButton(response.data.account_id);
              }, 3000);
            }
          })
          .catch((error) => {
            const errorData = {
              id: '',
              alertType: 'error',
              message: '',
            };
            if (error.code === 'ERR_NETWORK') {
              errorData.message = 'Network error. Please check your connection.';
            } else {
              errorData.message = error.response.data.violations.shift().message;
            }

            this.alertData.push(errorData);

            // scroll up top to show error message
            goTo(0);
          })
          .finally(() => {
            // this.$store.commit('setProgressLoader', false);
          });
      }
    },
  },
  mounted() {
    this.$store.commit('setAppBarExtraData', {
      enable: true,
      pageTitle: 'House Invitation',
      fabButton: {
        enable: false,
      },
    });
  },
  destroyed() {
    this.$store.commit('setAppBarExtraData', {
      enable: false,
      fabButton: {},
    });
  },
});
