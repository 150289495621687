
import Vue from 'vue';
import axios from 'axios';
// import AlertComponent from '@/components/AlertComponent.vue';
import goTo from 'vuetify/lib/services/goto';

export default Vue.extend({
  name: 'MetasSettingView',
  components: {
    // AlertComponent,
  },
  data: () => ({
    valid: false,
    name: '',
    nameRules: [
      (v: string) => !!v || 'Name is required',
    ],
    alertData: [] as object[],
    snackbar: {
      enable: false,
      timeout: 2000,
      text: '',
      color: 'white',
      light: true,
      dark: false,
    },
    isActive: false,
    oMetas: [] as object[],
    metaListUi: {
      height: 300,
      itemHeight: 64,
    },
    showMetaDeleteDialog: false,
    toDeleteMetaId: 0,
    showMetaFormDialog: false,
    metaFormDialogTitle: '',
    toEditMetaId: 0,
    searchText: '',
  }),
  watch: {
    //
  },
  computed: {
    formEl() {
      return (this.$refs.form as Vue & {
        validate: () => boolean,
        reset: () => boolean
      });
    },
    filteredItems() {
      return this.oMetas.filter((item) => {
        if (!this.searchText) return this.oMetas;
        const filteredItem = JSON.parse(JSON.stringify(item));
        return filteredItem.text.toLowerCase()
          .includes(this.searchText.toLowerCase());
      });
    },
  },
  methods: {
    clearSearch() {
      this.searchText = '';
    },
    setSnackbar(message: string, type: string, timeout = 2000) {
      this.snackbar.enable = true;
      this.snackbar.text = message;
      this.snackbar.timeout = timeout;

      if (type === 'error') {
        this.snackbar.color = 'red';
        this.snackbar.dark = true;
        this.snackbar.light = false;
      } else if (type === 'success') {
        this.snackbar.color = 'green';
        this.snackbar.dark = true;
        this.snackbar.light = false;
      } else if (type === 'info') {
        this.snackbar.color = 'white';
        this.snackbar.dark = false;
        this.snackbar.light = true;
      }
    },
    testSubmitData() {
      this.formEl.validate();
    },
    async loadMetas() {
      this.$store.commit('setProgressLoader', true);
      await axios
        .get(`${this.$store.getters.getApiUrl}/api/o_metas`, {
          headers: {
            'X-AUTH-TOKEN': this.$store.getters.getApiToken,
          },
          params: {
            account_id: this.$store.getters.getAccountId,
            'order[name]': 'ASC',
          },
          responseType: 'json',
        })
        .then((response) => {
          response.data.forEach((value: { id: number, name: string }) => {
            this.oMetas.push({
              text: value.name,
              value: value.id,
            });
          });
        })
        .catch((error) => {
          /** Authentication checker
           * @TODO optimize this code, make a base interface for this codes aside from validating
           * each page route changed in router/index.ts
           * */
          if (error.response.data.message === 'Invalid credentials.'
            || error.response.status === 401) {
            this.$store.commit('updateAuthenticatedAccount', {
              accountId: null,
              apiToken: null,
            });
            this.$router.push({
              name: 'login',
              params: {
                errorEx: 'Invalid authentication. Please login.',
              },
            });
          }

          const errorData = {
            id: '',
            alertType: 'error',
            message: '',
          };
          if (error.code === 'ERR_NETWORK') {
            errorData.message = 'Network error. Please check your connection.';
          } else {
            errorData.message = error.response.data.violations.shift().message;
          }

          // this.alertData.push(errorData);
          this.setSnackbar(errorData.message, errorData.alertType);
        })
        .finally(() => {
          this.$store.commit('setProgressLoader', false);
        });
    },
    triggerDeleteMeta(metaId: number) {
      this.showMetaDeleteDialog = true;
      this.toDeleteMetaId = metaId;
    },
    triggerEditMeta(metaId: number, metaName: string) {
      this.showMetaFormDialog = true;
      this.name = metaName;
      this.metaFormDialogTitle = 'Edit Meta';
      this.toEditMetaId = metaId;
    },
    async deleteMeta() {
      await axios
        .delete(`${this.$store.getters.getApiUrl}/api/o_metas/${this.toDeleteMetaId}`, {
          headers: {
            'X-AUTH-TOKEN': this.$store.getters.getApiToken,
          },
        })
        .then((response) => {
          if (response.status !== 204) {
            this.setSnackbar('There is something wrong while deleting. Please contact an administrator.', 'error');
          } else {
            this.showMetaDeleteDialog = false;
            this.toDeleteMetaId = 0;
            this.oMetas = [] as object[];
            this.loadMetas();
            this.clearSearch();
            this.setSnackbar('Successfully deleted a meta.', 'success');
          }
        })
        .catch((error) => {
          /** Authentication checker
           * @TODO optimize this code, make a base interface for this codes aside from validating
           * each page route changed in router/index.ts
           * */
          if (error.response.data.message === 'Invalid credentials.'
            || error.response.status === 401) {
            this.$store.commit('updateAuthenticatedAccount', {
              accountId: null,
              apiToken: null,
            });
            this.$router.push({
              name: 'login',
              params: {
                errorEx: 'Invalid authentication. Please login.',
              },
            });
          }

          const errorData = {
            id: '',
            alertType: 'error',
            message: '',
          };
          if (error.code === 'ERR_NETWORK') {
            errorData.message = 'Network error. Please check your connection.';
          } else {
            errorData.message = error.response.data.violations.shift().message;
          }

          this.setSnackbar(errorData.message, errorData.alertType);
        });
    },
    async submitButton() {
      this.testSubmitData();

      if (this.valid) {
        this.$store.commit('setProgressLoader', true);

        let apiUrl = `${this.$store.getters.getApiUrl}/api/o_metas`;
        const requestData = {
          name: this.name,
          accountId: `/api/accounts/${this.$store.getters.getAccountId}`,
        };
        const requestOptions = { headers: { 'X-AUTH-TOKEN': this.$store.getters.getApiToken } };
        let axiosCall = null;
        if (this.toEditMetaId !== 0) {
          // edit
          apiUrl += `/${this.toEditMetaId}`;
          axiosCall = axios.put(apiUrl, requestData, requestOptions);
        } else {
          axiosCall = axios.post(apiUrl, requestData, requestOptions);
        }

        await axiosCall
          .then((response) => {
            if (response.status !== 201 && response.status !== 200) {
              this.setSnackbar('There is something wrong while saving. Please contact an administrator.', 'error');
            } else {
              if (this.toEditMetaId !== 0) {
                this.setSnackbar('Successfully updated a meta', 'success');
              } else {
                this.setSnackbar('Successfully added a new meta', 'success');
              }

              this.formEl.reset();
              this.showMetaFormDialog = false;
              this.toEditMetaId = 0;
              this.name = '';
              this.oMetas = [] as object[];
              this.loadMetas();
            }
          })
          .catch((error) => {
            /** Authentication checker
             * @TODO optimize this code, make a base interface for this codes aside from validating
             * each page route changed in router/index.ts
             * */
            if (error.response.data.message === 'Invalid credentials.'
              || error.response.status === 401) {
              this.$store.commit('updateAuthenticatedAccount', {
                accountId: null,
                apiToken: null,
              });
              this.$router.push({
                name: 'login',
                params: {
                  errorEx: 'Invalid authentication. Please login.',
                },
              });
            }

            const errorData = {
              id: '',
              alertType: 'error',
              message: '',
            };
            if (error.code === 'ERR_NETWORK') {
              errorData.message = 'Network error. Please check your connection.';
            } else {
              errorData.message = error.response.data.violations.shift().message;
            }

            this.setSnackbar(errorData.message, errorData.alertType);

            // scroll up top to show error message
            goTo(0);
          })
          .finally(() => {
            this.$store.commit('setProgressLoader', false);
          });
      }
    },
  },
  created() {
    this.loadMetas();
  },
  mounted() {
    this.$store.commit('setBackButtonEnable', true);
    this.$store.commit('setAppBarExtraData', {
      enable: true,
      pageTitle: 'Metas',
      fabButton: {
        enable: true,
        icon: 'mdi-plus',
      },
    });
    this.$store.commit('setAppBarExtraAction', 'settingsMetasAddAction');

    this.$root.$on('appBarExtraFabEvent', (toggle: boolean, action: string) => {
      if (toggle && action === 'settingsMetasAddAction') {
        this.showMetaFormDialog = true;
        this.metaFormDialogTitle = 'Add Meta';
        this.name = '';
        this.toEditMetaId = 0;
      }
    });
  },
  destroyed() {
    this.$store.commit('setBackButtonEnable', false);
    this.$store.commit('setAppBarExtraData', {
      enable: false,
      fabButton: {},
    });
    this.$store.commit('setAppBarExtraAction', '');
  },
});
