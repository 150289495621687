
import Vue from 'vue';
import AlertComponent from '@/components/AlertComponent.vue';
import axios from 'axios';
import goTo from 'vuetify/lib/services/goto';

export default Vue.extend({
  name: 'AddItemView',
  components: {
    AlertComponent,
  },
  props: [],
  data: () => ({
    valid: false,
    boxId: null,
    boxIdRules: [
      (v: string) => !!v || 'Box Number is required',
      (v: string) => (!!v && /^-?[\d.]+(?:e-?\d+)?$/.test(v)) || 'Box Number should be number',
    ],
    boxes: [] as object[],
    boxDetailedInfo: {
      houseName: null,
      floorName: null,
      roomName: null,
      shelfName: null,
    },
    boxesLoading: true,
    itemName: '',
    itemNameRules: [
      (v: string) => !!v || 'Item Name is required',
    ],
    itemDescription: '',
    itemDescriptionRules: [],
    itemAmount: '',
    itemAmountRules: [
      (v: string) => !!v || 'Amount is required',
      (v: string) => (!!v && /^-?[\d.]+(?:e-?\d+)?$/.test(v)) || 'Amount should be number',
    ],
    browseImage: null,
    browseImageRules: [
      // (v: File) => !!v || 'Image is required',
      (v: File) => !v || v.size < (10 * 1000000) || 'Image size should be less than 10 mb',
    ],
    browseImageFile: '',
    previewSelectedImage: '',
    alertData: [] as object[],
    uploadedImageId: null,
    oMetas: [] as object[],
    oMetasFormValues: {},
  }),
  created() {
    this.loadMetas();
    this.loadBoxes();
  },
  mounted() {
    this.$store.commit('setAppBarExtraData', {
      enable: true,
      pageTitle: 'Add Item',
      fabButton: {
        enable: true,
        icon: 'mdi-content-save',
      },
    });
    this.$store.commit('setAppBarExtraAction', 'addItemAction');

    this.$root.$on('appBarExtraFabEvent', (toggle: boolean, action: string) => {
      if (toggle && action === 'addItemAction') {
        // save button for the form
        this.submitButton();
      }
    });
  },
  destroyed() {
    this.$store.commit('setAppBarExtraData', {
      enable: false,
      fabButton: {},
    });
    this.$store.commit('setAppBarExtraAction', '');
  },
  computed: {
    formEl() {
      return (this.$refs.form as Vue & {
        validate: () => boolean,
        reset: () => boolean
      });
    },
    previewSelectedImageEl() {
      return (this.$refs.previewSelectedImage as Vue & {
        el: object,
      });
    },
  },
  watch: {
    // houseId() {
    //   if (this.houseId) {
    //     this.floorDisabled = false;
    //     this.floors = [] as object[];
    //     this.loadFloors(this.houseId);
    //   }
    // },
    boxId() {
      if (this.boxId) {
        const boxDetailedInfo = this.boxes.find((box) => {
          const data = JSON.parse(JSON.stringify(box));
          return data.value === this.boxId ? data : null;
        });

        const data = JSON.parse(JSON.stringify(boxDetailedInfo));
        this.boxDetailedInfo.houseName = data.additionalInfo.houseName;
        this.boxDetailedInfo.floorName = data.additionalInfo.floorName;
        this.boxDetailedInfo.roomName = data.additionalInfo.roomName;
        this.boxDetailedInfo.shelfName = data.additionalInfo.shelfName;
      }
    },
    browseImage() {
      const el = this.previewSelectedImageEl.$el;
      if (this.browseImage) {
        this.previewSelectedImage = URL.createObjectURL(this.browseImage);
        el?.removeAttribute('style');
      } else {
        this.previewSelectedImage = '';
        el?.setAttribute('style', 'display: none;');
      }
    },
  },
  methods: {
    browseImageFileOnChangeEvent(file: never) {
      // eslint-disable-next-line prefer-destructuring
      this.browseImageFile = file;
    },
    testSubmitData() {
      this.formEl.validate();
    },
    async loadMetas() {
      await axios
        .get(`${this.$store.getters.getApiUrl}/api/o_metas`, {
          headers: {
            'X-AUTH-TOKEN': this.$store.getters.getApiToken,
          },
          params: {
            account_id: this.$store.getters.getAccountId,
            'order[name]': 'ASC',
          },
          responseType: 'json',
        })
        .then((response) => {
          response.data.forEach((value: { id: number, name: string }) => {
            this.oMetas.push({
              text: value.name,
              value: value.id,
            });
          });
        })
        .catch((error) => {
          /** Authentication checker
           * @TODO optimize this code, make a base interface for this codes aside from validating
           * each page route changed in router/index.ts
           * */
          if (error.response.data.message === 'Invalid credentials.'
            || error.response.status === 401) {
            this.$store.commit('updateAuthenticatedAccount', {
              accountId: null,
              apiToken: null,
            });
            this.$router.push({
              name: 'login',
              params: {
                errorEx: 'Invalid authentication. Please login.',
              },
            });
          }

          const errorData = {
            id: '',
            alertType: 'error',
            message: '',
          };
          if (error.code === 'ERR_NETWORK') {
            errorData.message = 'Network error. Please check your connection.';
          } else {
            errorData.message = error.response.data.violations.shift().message;
          }

          this.alertData.push(errorData);
          // this.setSnackbar(errorData.message, errorData.alertType);
        });
    },
    async loadBoxes() {
      // this.$store.commit('setProgressLoader', true);
      await axios
        .get(`${this.$store.getters.getApiUrl}/api/boxes/get/all`, {
          headers: {
            'X-AUTH-TOKEN': this.$store.getters.getApiToken,
          },
          params: {
            account_id: this.$store.getters.getAccountId,
            'order[number]': 'DESC',
          },
          responseType: 'json',
        })
        .then((response) => {
          response.data.forEach((value: {
            id: number,
            number: number,
            // eslint-disable-next-line camelcase
            cupboard_id: {
              name: string,
              // eslint-disable-next-line camelcase
              room_id: {
                name: string,
                // eslint-disable-next-line camelcase
                floor_id: {
                  name: string,
                  // eslint-disable-next-line camelcase
                  home_id: {
                    name: string,
                  },
                }
              },
            },
          }) => {
            this.boxes.push({
              text: `Box ${value.number} - House ${value.cupboard_id.room_id.floor_id.home_id.name}`,
              value: value.id,
              additionalInfo: {
                houseName: value.cupboard_id.room_id.floor_id.home_id.name,
                floorName: value.cupboard_id.room_id.floor_id.name,
                roomName: value.cupboard_id.room_id.name,
                shelfName: value.cupboard_id.name,
              },
            });
          });

          // auto select a box if there is only one selection
          if (this.boxes.length === 1) {
            const singleBoxData = JSON.parse(JSON.stringify(this.boxes[0]));
            this.boxId = singleBoxData.value;
          }
        })
        .catch((error) => {
          /** Authentication checker
           * @TODO optimize this code, make a base interface for this codes aside from validating
           * each page route changed in router/index.ts
           * */
          if (error.response.data.message === 'Invalid credentials.'
            || error.response.status === 401) {
            this.$store.commit('updateAuthenticatedAccount', {
              accountId: null,
              apiToken: null,
            });
            this.$router.push({
              name: 'login',
              params: {
                errorEx: 'Invalid authentication. Please login.',
              },
            });
          }

          const errorData = {
            id: '',
            alertType: 'error',
            message: '',
          };
          if (error.code === 'ERR_NETWORK') {
            errorData.message = 'Network error. Please check your connection.';
          } else {
            errorData.message = error.response.data.violations.shift().message;
          }

          this.alertData.push(errorData);
        })
        .finally(() => {
          this.boxesLoading = false;
          // this.$store.commit('setProgressLoader', false);
        });
    },
    async uploadImageApi() {
      const data = new FormData();
      data.append('image', this.browseImageFile);
      // data.append('is_landscape', 'false');

      return axios
        .post(`${this.$store.getters.getApiUrl}/api/images/upload`, data, {
          headers: {
            'X-AUTH-TOKEN': this.$store.getters.getApiToken,
          },
        })
        .catch((error) => {
          /** Authentication checker
           * @TODO optimize this code, make a base interface for this codes aside from validating
           * each page route changed in router/index.ts
           * */
          if (error.response.data.message === 'Invalid credentials.'
            || error.response.status === 401) {
            this.$store.commit('updateAuthenticatedAccount', {
              accountId: null,
              apiToken: null,
            });
            this.$router.push({
              name: 'login',
              params: {
                errorEx: 'Invalid authentication. Please login.',
              },
            });
          }

          const errorData = {
            id: '',
            alertType: 'error',
            message: '',
          };
          if (error.code === 'ERR_NETWORK') {
            errorData.message = 'Network error. Please check your connection.';
          } else if (error.response.data.violations) {
            errorData.message = error.response.data.violations.shift().message;
          } else {
            errorData.message = 'Server side upload configuration error. Please contact an administrator.';
          }

          // scroll up top to show error message
          goTo(0);

          this.alertData.push(errorData);
        });
    },
    async submitButton() {
      this.testSubmitData();
      this.alertData = [] as object[];

      if (this.valid) {
        this.$store.commit('setProgressLoader', true);

        const boxRequestData = {
          name: this.itemName,
          description: this.itemDescription,
          amount: this.itemAmount,
          boxId: this.boxId,
          imageId: null,
          metas: [] as object[],
        };

        Object.entries(this.oMetasFormValues)
          .forEach((value) => {
            boxRequestData.metas.push({
              oMetaId: value[0],
              value: value[1],
            });
          });

        if (this.browseImageFile) {
          await this.uploadImageApi()
            .then((response) => {
              // this.uploadedImageId = response?.data.image_id;
              boxRequestData.imageId = response?.data.image_id;
            });
        }

        await axios
          .post(`${this.$store.getters.getApiUrl}/api/items`, boxRequestData, {
            headers: { 'X-AUTH-TOKEN': this.$store.getters.getApiToken },
          })
          .then((response) => {
            if (response.status !== 201) {
              this.alertData.push({
                id: this.alertData.length,
                alertType: 'error',
                message: 'There is something wrong while saving. Please contact an administrator.',
              });
            } else {
              this.alertData.push({
                id: this.alertData.length,
                alertType: 'success',
                message: 'Successfully added a new item',
              });
              this.formEl.reset();

              // scroll up top
              goTo(0);
            }
          })
          .catch((error) => {
            /** Authentication checker
             * @TODO optimize this code, make a base interface for this codes aside from validating
             * each page route changed in router/index.ts
             * */
            if (error.response.data.message === 'Invalid credentials.'
              || error.response.status === 401) {
              this.$store.commit('updateAuthenticatedAccount', {
                accountId: null,
                apiToken: null,
              });
              this.$router.push({
                name: 'login',
                params: {
                  errorEx: 'Invalid authentication. Please login.',
                },
              });
            }

            const errorData = {
              id: '',
              alertType: 'error',
              message: '',
            };
            if (error.code === 'ERR_NETWORK') {
              errorData.message = 'Network error. Please check your connection.';
            } else {
              errorData.message = error.response.data.violations.shift().message;
            }

            this.alertData.push(errorData);

            // scroll up top to show error message
            goTo(0);
          })
          .finally(() => {
            this.$store.commit('setProgressLoader', false);
          });
      } else {
        // scroll up top to show error message
        goTo(0);
      }
    },
  },
});
